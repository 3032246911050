<template>
  <div
    class="notificazion animate__animated"
    @click="closeAlert"
    :class="[
      alert2?.type == 'success' ? 'alert-success' : 'alert-error',
      alert2?.status === true && 'bounceInLeft animate__bounceInRight',
    ]"
  >
    <span class="alert_message_box">{{ alert2?.message }}</span>
    <span class="closebtn"><i class="fa fa-times"></i></span>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["alert2"]),
  },

  methods: {
    ...mapActions(["setAlert"]),
    closeAlert: function () {
      this.setAlert({
        status: false,
        type: "",
        message: "",
      });
    },
  },
};
</script>
<style>
.alert-error {
  background-color: #862c26 !important;
  color: #fff !important;
}

.alert-success {
  background-color: #355e03 !important;
  color: rgb(231, 231, 233) !important;
}

/* The close button */
.notificazion .closebtn {
  margin-left: 22px;
  color: #f7f7f7 !important;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 20px;
  text-transform: capitalize !important;
  text-align: left;
  padding-left: 80px;
}

.notificazion .closebtn i {
  color: #f7f7f7 !important;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: rgb(165, 165, 165);
  /* background: #241515; */
}

.notificazion {
  position: fixed;
  top: 16px;
  right: 10px;
  transition: var(--mainTransition);
  display: none;
  padding: 17px 17px 17px 12px;
  border-radius: 3px;
  /* background: #202124 !important; */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-weight: 400;
  /* box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%); */
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  z-index: 833440000 !important;
}

.notificazion .alert_message_box {
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 40px;
  cursor: pointer;
}

.bounceInLeft {
  display: unset !important;
}
</style>
