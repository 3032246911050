<template>
    <div id="footer-top" class="row main-wrapper">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="info-footer">
                <div class="logo-footer">
                    <!-- -small, -medium, -big -->
                    <div class="image--big mb-20">
                        <router-link :to="{ name: 'Home' }">
                            <img
                                src="@/assets/img/logos/logo-new-1.png"
                                data-src="@/assets/img/logos/logo-new-1.png"
                                alt="cashwyre logo"
                                style="opacity: 1"
                            />
                        </router-link>
                    </div>
                </div>
                <p class="description mb-0">
                    Send and receive money securely across borders. It's fast
                    and easy.
                    <router-link
                        :to="{ name: 'Testimonials' }"
                        class="simple--dotted"
                        >Read up some stories from our users</router-link
                    >
                </p>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
            <div class="list-element--text">
                <h6>Featured</h6>
                <ul>
                    <li>
                        <router-link :to="{ name: 'WalletServices' }"
                            >Wallet Services</router-link
                        >
                    </li>
                    <li>
                        <router-link :to="{ name: 'PaymentServices' }"
                            >Payment Services</router-link
                        >
                    </li>
                    <li>
                        <router-link :to="{ name: 'BecomeLiquidityProvider' }"
                            >Become a Partner</router-link
                        >
                    </li>
                    <li>
                        <a
                            href="https://paylink.cashwyre.io/"
                            target="_blank"
                            rel="noopener noreferrer"
                            >Cashwyre Paylink</a
                        >
                    </li>
                    <li>
                        <router-link :to="{ name: 'Business' }"
                            >Business</router-link
                        >
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-2">
            <div class="list-element--text">
                <!-- <h6>Support</h6> -->
                <h6>Quick Links</h6>
                <ul>
                    <li>
                        <router-link :to="{ name: 'About' }">About</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Pricing' }"
                            >Pricing</router-link
                        >
                    </li>
                    <li>
                        <router-link :to="{ name: 'Contact' }"
                            >Contact</router-link
                        >
                    </li>
                    <li>
                        <router-link :to="{ name: 'FAQs' }">FAQs</router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
            <div class="list-element--text">
                <h6>Need to make an enquiry?</h6>

                <p class="mb-10" v-if="allData?.socialMediaLinks">
                    <a
                        :href="`mailto:${allData?.socialMediaLinks?.email}`"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><i class="fa fa-envelope"></i>
                        {{ allData?.socialMediaLinks?.email }}</a
                    >
                </p>
                <!-- <p class="mb-10">
                    <a
                        href="tel:+234(0)8038832695"
                        target="_blank"
                        rel="noopener noreferrer"
                        ><i class="fa fa-phone"></i> +234(0)8038832695</a
                    >
                </p>
                <p class="mb-10">
                    <i class="fa fa-map-marker-alt"></i> Lagos, Nigeria
                </p> -->

                <div class="social-element--small">
                    <ul v-if="allData?.socialMediaLinks">
                        <!-- <li>
                            <a
                                :href="
                                    allData?.socialMediaLinks?.faceBook
                                        ? allData?.socialMediaLinks?.faceBook
                                        : ''
                                "
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li> -->
                        <li>
                            <a
                                :href="
                                    allData?.socialMediaLinks?.linkedin
                                        ? allData?.socialMediaLinks?.linkedin
                                        : ''
                                "
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-linkedin-in"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                :href="
                                    allData?.socialMediaLinks?.instagram
                                        ? allData?.socialMediaLinks?.instagram
                                        : ''
                                "
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                :href="
                                    allData?.socialMediaLinks?.twitter
                                        ? allData?.socialMediaLinks?.twitter
                                        : ''
                                "
                                target="_blank"
                                rel="noopener noreferrer"
                                ><i class="fab fa-twitter"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["allData"]),
    },
};
</script>

<style lang="scss" scoped>
#footer-top {
    padding-top: 40px;
    padding-bottom: 50px;

    .col-12 > div {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    h6 {
        font-size: 1.125rem;
        color: var(--white);
    }

    p {
        font-size: 15px;
        color: var(--grey2);
    }

    a {
        font-size: 15px;
        color: var(--grey2);

        &,
        i {
            -o-transition: 0.4s ease;
            -moz-transition: 0.4s ease;
            -webkit-transition: 0.4s ease;
            transition: 0.4s ease;
        }

        &:hover {
            color: var(--offWhite);
            opacity: 1;
        }
    }

    .info-footer {
        & .description {
            max-width: 300px;

            a {
                margin-top: 8px;
            }
        }
    }

    .fa-map-marker-alt,
    .fa-envelope,
    .fa-phone {
        font-size: 0.75rem;
        margin-right: 0.125rem;
    }

    .fa-phone {
        transform: rotate(90deg);
    }

    [class|="social-element"] {
        li a i {
            color: var(--orangeDark);

            &:hover {
                color: var(--offWhite);
            }
        }
    }

    @media screen and (max-width: 769px) {
        .main-wrapper {
            padding-bottom: 40px;

            & > div {
                margin-bottom: 40px;
            }
        }
    }
}
</style>
