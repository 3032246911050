<template>
    <div class="cashwyre-service">
        <Cashwyre4BusinessServices />
        <BusinessCallToAction />
    </div>
</template>

<script>
import Cashwyre4BusinessServices from "../components/business/Cashwyre4BusinessServices.vue";
import BusinessCallToAction from "../components/business/BusinessCallToAction.vue";
import { mapActions } from "vuex";
import "@/mixins";
import $ from "jquery";

export default {
    components: {
        Cashwyre4BusinessServices,
        BusinessCallToAction,
    },

    methods: {
        ...mapActions(["setCurrentPage", "setBannerSettings"]),
        addPageStyles() {
            if (document.querySelector(".navbar-placeholder")) {
                $(".navbar").removeClass("is-transparent navbar-light");
                $(".navbar").addClass("navbar-faded");
                $(".navbar-item .button-signup ").removeClass("light-btn");
                $(".navbar-item .button-signup").addClass("primary-btn");
            }
        },

        setBannerStyles: function () {
            this.setBannerSettings({
                title: "Cashwyre For Business",
                prevLinkText: null,
                currentLinkText: null,
                description: null,
                // "We are simplifying cross-border payments for businesses globally",
                buttonUrl: "Get Started Today",
                imageUrl: require("../assets/img/banner/banner-1.png"),
            });
        },
    },

    mounted() {
        this.setCurrentPage(this.$router.history.current.name);

        this.addPageStyles();
        this.setBannerStyles();

        $(window).scroll(() => {
            this.addPageStyles();
        });
    },
};
</script>

<style scoped>
/* .navbar-wrapper.navbar-light .navbar-item,
.navbar-wrapper.navbar-light .navbar-link {
    color: #4a4a4a !important;
} */
</style>
