import axios from "axios";
import $ from "jquery";

export const getReceiveCountries = async ({ commit, state }) => {
  const url = `/v1.0/Utility/getReceiveCountries`;

  // let options = {
  //   method: 'POST',
  //   headers: {
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-Type': 'application/json',
  //   }
  // };

  await axios
    .post(url)
    .then((res) => {
      let countries = res.data;

      if (countries) {
        // console.log(countries);
        // countries.forEach(country => {
        //   country.imageUrl = `${state.countryFlagUrl}/${country.code}`
        // });

        commit("SAVE_COUNTRIES", countries);
      }
    })
    .catch((err) => {
      // console.error(err);
      state.alertModalMessage = err.message;
    });
};

export const getCountryCurrencies = async ({ commit, state }) => {
  const url = `/v1.0/Utility/getCountryCurrencies`;

  await axios
    .post(url)
    .then((res) => {
      let countryCurrencies = res.data;

      if (countryCurrencies) {
        // console.log(countryCurrencies);
        // countryCurrencies.forEach(currency => {
        //   currency.imageUrl = `${state.countryFlagUrl}/${currency.countryCode}`
        // });

        commit("SAVE_COUNTRY_CURRENCIES", countryCurrencies);
      }
    })
    .catch((err) => {
      // console.error(err);
      state.alertModalMessage = err.message;
    });
};

export const saveCurrencyInfo = async ({ commit, state }) => {
  const url = `/v1.0/Utility/getCurrencyInfo`;

  let data = {
    appId: state.AppId,
    requestId: state.RequestId,
  };

  await axios
    .post(url, data)
    .then((res) => {
      let currencyInfo = res?.data;

      if (currencyInfo) {
        // console.log(currencyInfo);
        // currencyInfo.forEach(currency => {
        //   currency.imageUrl = `${state.countryFlagUrl}/${currency.countryCode}`
        // });

        commit("SAVE_CURRENCY_INFO", currencyInfo);
      }
    })
    .catch((err) => {
      // console.error(err);
      state.alertModalMessage = err.message;
    });
};

export const getCrypto4CashInfo = async ({ commit, state }) => {
  const url = `/v1.0/Crypto4Cash/getCrypto4CashInfo`;

  let data = {
    appId: state.AppId,
    requestId: state.RequestId,
  };

  await axios
    .post(url, data)
    .then((res) => {
      let cashInfo = res?.data?.data;

      if (cashInfo) {
        // console.log("getCrypto4CashInfo", cashInfo);
        // currencyInfo.forEach(currency => {
        //   currency.imageUrl = `${state.countryFlagUrl}/${currency.countryCode}`
        // });

        commit("SAVE_CRYPTO4CASH_INFO", cashInfo);
      }
    })
    .catch((err) => {
      // console.error(err);
      state.alertModalMessage = err.message;
    });
};

export const getAllGlobalCurrencies = async ({ commit, state }) => {
  const url = `/v1.0/Country/getAllCountries`;

  let data = {
    appId: state.AppId,
    requestId: state.RequestId,
    country: "",
    phoneCode: "",
  };

  await axios
    .post(url, data)
    .then((res) => {
      if (res.data.success) {
        let allCountries = res.data.data;
        // console.log(allCountries);

        commit("SAVE_ALL_GLOBAL_COUNTRIES", allCountries);
      }
    })
    .catch((err) => {
      // console.error(err);
      state.alertModalMessage = err.message;
    });
};

export const getCalculationResult = async (
  { commit, state, dispatch },
  data
) => {
  const url = `/v1.0/SendMoney/calculateSendMoney`;
  let payload;

  await axios
    .post(url, data)
    .then((res) => {
      let result = res.data;

      if (result.data) {
        // console.log(result);
        commit("CALCULATION_RESULT", result.data);
      }
    })
    .catch((err) => {
      // console.error(err);
      dispatch("setIsCalculating", false);

      state.alertModalMessage = err.message;

      payload = {
        status: true,
        type: "error",
        message: err.message,
      };

      dispatch("setAlertModalStatus", payload);
    });
};

export const setIsCalculating = ({ commit }, status) => {
  commit("SET_IS_CALCULATING", status);
};

export const toggleModalActive = ({ commit }, status) => {
  commit("TOGGLE_SUBSCRIPTION_MODAL", status);
};

export const toggleCookiesActive = ({ commit }, status) => {
  commit("TOGGLE_COOKIES_ACTIVE", status);
};

export const toggleIsCookiesSet = ({ commit }, status) => {
  commit("IS_COOKIES_SET", status);
};

// export const getAuthentication = async ({ state }) => {
//   const url = `/v1.0/Authenticate/authenticateEndpoints`;
//   let data = {
//     AppId: state.AppId,
//     RequestId: state.RequestId,
//   };

//   let result = await axios.post(url, data);

//   try {
//     if (result.data.success) {
//       // commit("SAVE_INVESTMENT", result.data.data);
//       console.log(result.data.data);
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

export const saveTestimonies = async ({ commit, state }) => {
  const url = `/v1.0/Testimony/getTestimonies`;

  await axios
    .post(url)
    .then((res) => {
      let allTestimonies = res.data;

      if (allTestimonies) {
        // console.log(allTestimonies);
        commit("SAVE_TESTIMONIES", allTestimonies);
      }
    })
    .catch((err) => {
      // console.error(err);
      state.alertModalMessage = err.message;
    });
};

export const saveAllData = async ({ commit, state }) => {
  const url = `/v1.0/AppVersion/getVersion`;

  await axios
    .post(url)
    .then((res) => {
      if (res.data.success === true) {
        let allData = res.data.data;
        // console.log("allData", allData);

        commit("SAVE_ALL_DATA", allData);
      }
    })
    .catch((error) => {
      // console.error(error);
      state.alertModalMessage = error.message;

      // let payload = {
      //   status: true,
      //   type: "error",
      //   message: error.message,
      // };

      // console.log(state.alertModalMessage);
      // dispatch('setAlertModalStatus', payload)
    });
};

// export const getFeedbacks = ({ commit, state }, payload) => {
//   const url = `/v1.0/Feedback/getFeedbacks`;

//   var data = {
//     AppId: state.AppId,
//     RequestId: state.RequestId + new Date(),
//     UserCode: payload.userCode,
//     FromDate: payload.from,
//     ToDate: payload.to,
//     PageNumber: payload.page,
//     PageSize: 20,
//   };

//   axios
//     .post(url, data)
//     .then((res) => {
//       if (res.data.success) {
//         let responseData = res.data.data;
//         // console.log(responseData);
//         commit("SAVE_FEEDBACKS", responseData);
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// export const getPositiveFeedbacks = ({ commit, state }, payload) => {
//   const url = `/v1.0/Feedback/getPositiveFeedbacks`;

//   var data = {
//     AppId: state.AppId,
//     RequestId: state.RequestId + new Date(),
//     UserCode: payload.userCode,
//     FromDate: payload.from,
//     ToDate: payload.to,
//     PageNumber: payload.page,
//     PageSize: 20,
//   };

//   axios
//     .post(url, data)
//     .then((res) => {
//       if (res.data.success) {
//         let responseData = res.data.data;
//         // console.log(responseData);
//         commit("SAVE_POSITIVE_FEEDBACKS", responseData);
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

export const setPageLoading = ({ commit }, status) => {
  commit("SET_PAGE_LOADING", status);
};

export const setPageProcessing = ({ commit }, status) => {
  commit("SET_PAGE_PROCESSING", status);
};

export const setCurrentPage = ({ commit }, payload) => {
  commit("CURRENT_SITE_PAGE", payload);
  if (document.querySelector(".navbar-placeholder")) {
    if ($("#header_hero").hasClass("greyBackground")) {
      $(".navbar-menu").removeClass("is-active");
      $(".custom-burger").removeClass("is-active");
      $(".icon-box-toggle").removeClass("active");
    }
  }
};

export const setAlertModalStatus = ({ commit }, status) => {
  commit("SET_ALERT_MODAL_STATUS", status);
};

export const setAlert = ({ commit }, payload) => {
  commit("SET_ALERT2", payload);
};

export const setActionLoading = ({ commit }, status) => {
  commit("SET_ACTION_LOADING", status);
};

export const setModalLoading = ({ commit }, payload) => {
  commit("SET_MODAL_LOADING", payload);
};

export const setVerifyAction = ({ commit }, status) => {
  commit("SET_VERIFY_ACTION", status);
};

export const setVerifyActionMessage = ({ commit }, status) => {
  commit("SET_VERIFY_ACTION_MESSAGE", status);
};

export const setBannerSettings = ({ commit }, payload) => {
  commit("BANNER_SETTINGS", payload);
};

// export const setCalculateModal = ({ commit }, payload) => {
//   commit("SET_CALCULATE_MODAL", payload);
// };
