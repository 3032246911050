<template>
    <div id="cookies" v-if="!isCookiesSet">
        <div
            class="cookie-image"
            title="Open Cookie Settings"
            aria-label="Open Cookie Settings"
            v-show="cookiesImageActive"
            once
            @click="openCookieSetting"
        >
            <!-- <div class="fa fa-cookie-bite" alt="cookie icon"></div> -->
            <img src="@/assets/img/others/cookie-icon.png" alt="cookie icon" />
        </div>

        <div class="cookies-wrapper" v-show="cookiesActive" once>
            <button
                class="close-cookie"
                title="Close Cookie Settings"
                aria-label="Close Cookie Settings"
                @click="closeCookieSetting"
            >
                X
            </button>

            <div class="content">
                <h4>Cookies Consent</h4>

                <p>
                    Cashwyre uses cookies to improve your overall experience. By
                    clicking "Accept All", you consent to our use of cookies.
                    <router-link :to="{ name: 'PrivacyPolicy' }">
                        Read more<span class="sr-only">
                            on privacy policy page</span
                        >
                    </router-link>
                </p>

                <div class="buttons">
                    <button @click="acceptCookieUse">Accept All</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            cookiesImageActive: null,
        };
    },

    computed: {
        ...mapState(["cookiesActive"]),
        ...mapGetters(["getIsCookiesSet"]),

        isCookiesSet() {
            let isSet;
            if (this.getIsCookiesSet) {
                return (isSet = this.getIsCookiesSet);
            }
            return isSet;
        },
    },

    methods: {
        ...mapActions(["toggleCookiesActive", "toggleIsCookiesSet"]),

        checkIfCookieAccepted() {
            let checkCookie = document.cookie.indexOf("CookieBy=Cashwyre");

            // check if cookie is set, call function to update status to hide the cookie content box or show it
            checkCookie !== -1
                ? this.toggleIsCookiesSet(true)
                : this.toggleIsCookiesSet(false);
        },

        showCookieImage() {
            // check cookie status on mount
            this.checkIfCookieAccepted();

            const scrollTrigger = 400;

            // show cookie image on scroll to height && if cookie content box is not active && if cookie status is false
            window.addEventListener("scroll", () => {
                (document.body.scrollTop > scrollTrigger ||
                    document.documentElement.scrollTop > scrollTrigger) &&
                !this.cookiesActive &&
                !this.isCookiesSet
                    ? (this.cookiesImageActive = true)
                    : (this.cookiesImageActive = false);
            });
        },

        openCookieSetting() {
            // hide cookie image, call function to set cookieActive to true to show content
            this.cookiesImageActive = false;
            this.toggleCookiesActive(true);
        },

        closeCookieSetting() {
            // show cookie image, call function to set cookieActive to false to hide content
            this.cookiesImageActive = true;
            this.toggleCookiesActive(false);
        },

        acceptCookieUse() {
            //setting cookie for 1 year, expire automatically afterwards
            document.cookie =
                "CookieBy=Cashwyre; max-age=" + 60 * 60 * 24 * 365;

            // if cookie is set hide cookie content box else alert an error
            // call function to update cookie set status to true to hide entire element (cookie image, cookie content box)
            if (document.cookie) {
                this.toggleIsCookiesSet(true);
                // this.toggleCookiesActive(false);
                // this.cookiesImageActive = false;
            } else {
                alert(
                    "Cookie can not be set! Please unblock this site from the cookie setting of your browser."
                );
            }
        },
    },

    mounted() {
        this.showCookieImage();
    },
};
</script>

<style lang="scss" scoped>
#cookies {
    .hide {
        display: none !important;
    }

    .cookie-image {
        position: fixed;
        bottom: 20px;
        left: 20px;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        z-index: 1000;
        cursor: pointer;
        z-index: 100;

        img,
        .fa {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--orangeDark);
            border-radius: 50%;
            padding: 0.5rem;
        }

        .fa {
            font-size: 2.5rem;
            color: var(--orangeDark);
        }
    }

    a,
    button,
    .cookie-image {
        -o-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    h4 {
        font-size: 1.125rem;
        color: var(--white);
        margin-bottom: 10px;
    }

    p {
        color: var(--grey2);
        margin-bottom: 20px;
        font-size: 0.875rem;
    }

    a {
        font-weight: 500;
        color: var(--orangeDark);
        -moz-text-decoration-line: underline;
        text-decoration-line: underline;

        &:hover {
            -moz-text-decoration-line: none;
            text-decoration-line: none;
            opacity: 0.7;
        }
    }

    button {
        font-weight: 500;
        background: var(--orangeDark);
        border: 2px solid transparent;
        border-radius: 10px;
        padding: 0.125rem 0.65rem;
        outline: none;
        cursor: pointer;

        &:hover,
        &.close-cookie {
            background: transparent;
            color: var(--orangeDark);
            border-color: var(--orangeDark);
        }

        &.close-cookie {
            position: absolute;
            top: 0;
            right: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 0;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    .cookies-wrapper {
        position: fixed;
        bottom: 5px;
        left: 5px;
        max-width: 460px;
        font-size: 0.875rem;
        background-color: rgba(22, 23, 25, 0.935);
        color: var(--grey2);
        padding: 1.5rem 1.5rem;
        line-height: 24px;
        border-radius: 10px;
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        // box-shadow: 0 0.125rem 0.125rem rgb(0 0 0 / 15%);
        z-index: 100;

        .buttons {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
}
</style>