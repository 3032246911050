import { render, staticRenderFns } from "./TheNavbarIndex.vue?vue&type=template&id=4637a6ca&scoped=true"
import script from "./TheNavbarIndex.vue?vue&type=script&lang=js"
export * from "./TheNavbarIndex.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4637a6ca",
  null
  
)

export default component.exports