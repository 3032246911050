<template>
    <section id="business_cta" data-aos="fade" data-padding="sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="ctabox-element--center--round--light">
                        <div class="text">
                            <h2>Get started with Cashwyre For Business!</h2>
                            <p class="mb-0">
                                Send and receive money freely, easily, and
                                securely across borders.<br />
                                It's fast, easy, and secure.
                            </p>
                            <div class="cta_link">
                                <a
                                    href="https://business.cashwyre.com/login"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="btn--big--border--light--round"
                                >
                                    Get started
                                </a>
                            </div>
                        </div>

                        <div class="cta_image">
                            <img
                                src="@/assets/img/business/dashboard-new.png"
                                alt="Cashwyre For Business dashboard"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#business_cta {
    [class|="ctabox-element"] {
        justify-content: center;
        padding-bottom: 0;
        background: linear-gradient(
            to bottom right,
            var(--black2),
            var(--black2)
        );

        .text {
            margin-bottom: 50px;

            @media screen and (max-width: 426px) {
                & h2 {
                    font-size: 26px;
                }
            }

            & p {
                color: var(--grey2);
            }
        }

        .cta_image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                // height: 340px !important;
                object-fit: contain;
                margin: auto;

                @media screen and (max-width: 1220px) {
                    height: 250px !important;
                }

                @media screen and (max-width: 1024px) {
                    height: 200px !important;
                }

                @media screen and (max-width: 768px) {
                    height: 145px !important;
                }
            }

            @media screen and (max-width: 640px) {
                display: none;
            }
        }
    }

    .cta_link {
        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px !important;
            font-size: 15px;

            &:hover {
                color: var(--orangeLight);
            }

            &:first-child {
                background: var(--orangeDark) !important;
                border-color: var(--orangeDark) !important;

                &:hover {
                    background: var(--white) !important;
                    border-color: var(--white) !important;
                }
            }
        }
    }
}
</style>