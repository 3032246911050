var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.currentSitePage == 'Home',
    [
      'Business',
      'Services',
      'WalletServices',
      'PaymentServices',
      'Pricing',
      'About',
      'Contact',
      'FAQs',
      'Testimonials',
      'PrivacyPolicy',
      'TermsConditions',
      'CryptoForCash',
      'Crypto4CashStatus',
      'DeleteAccount',
      'DeleteAccountStatusPage',
      'BecomeLiquidityProvider',
    ].includes(_vm.currentSitePage),
    ['NotFound'].includes(_vm.currentSitePage),
  ]},[(
      [
        'Home',
        'Business',
        'Services',
        'WalletServices',
        'PaymentServices',
        'Pricing',
        'About',
        'Contact',
        'FAQs',
        'Testimonials',
        'PrivacyPolicy',
        'TermsConditions',
        'BecomeLiquidityProvider',
        'CryptoForCash',
        'Crypto4CashStatus',
        'DeleteAccount',
        'DeleteAccountStatusPage',
        'NotFound',
      ].includes(_vm.currentSitePage)
    )?_vm._m(0):_vm._e(),(_vm.currentSitePage == 'Home')?_vm._m(1):_vm._e(),(
      [
        'Business',
        'Services',
        'WalletServices',
        'PaymentServices',
        'Pricing',
        'About',
        'Contact',
        'FAQs',
        'Testimonials',
        'PrivacyPolicy',
        'TermsConditions',
        'BecomeLiquidityProvider',
      ].includes(_vm.currentSitePage)
    )?_vm._m(2):_vm._e(),(_vm.currentSitePage == 'NotFound')?_vm._m(3):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('Navbar')
},function (){var _vm=this,_c=_vm._self._c;return _c('HomeBanner')
},function (){var _vm=this,_c=_vm._self._c;return _c('PageHeader')
},function (){var _vm=this,_c=_vm._self._c;return _c('NotFound')
}]

export { render, staticRenderFns }