<template>
  <section id="download-app" data-aos="fade" data-padding="sm">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="ctabox-element--center--round--light">
            <div class="text">
              <h2>Send and receive money across borders today!</h2>
              <p class="mb-0">
                Send and receive money freely, easily, and securely across
                borders.<br />
                It's fast, easy, and secure.
              </p>
              <div class="app-stores">
                <a
                  :href="allData ? allData.iosAppURL : null"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn--big--border--light--round"
                  title="App Store"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25.42 29.39"
                    width="16"
                    height="20"
                    fill="#fff"
                  >
                    <title>App Store</title>
                    <path d="M20.55,0a7,7,0,0,1-7,7A7,7,0,0,1,20.55,0Z"></path>
                    <path
                      class="cls-1"
                      d="M19.44,15.55a5.66,5.66,0,0,1,4.29-5.2,8.37,8.37,0,0,0-6.45-3.1,8.46,8.46,0,0,0-3.4.93,2.17,2.17,0,0,1-1.83,0,8.54,8.54,0,0,0-3.41-.93C3.87,7.25,0,11.38,0,16.47,0,23.6,3.38,29.39,7.56,29.39A13,13,0,0,0,12,28.27a2.6,2.6,0,0,1,1.9,0,13,13,0,0,0,4.45,1.12c3.23,0,6-3.46,7.06-8.32C22.08,20.85,19.44,18.46,19.44,15.55Z"
                    ></path>
                  </svg>
                  App Store
                </a>
                <a
                  :href="allData ? allData.googleAppURL : null"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn--big--border--light--round"
                  title="Google Play"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25 27"
                    width="16"
                    height="20"
                    fill="#fff"
                  >
                    <title>Google Play</title>
                    <path
                      d="M16.1545 12.3557L4.51606 0.685547L19.3239 9.18633L16.1545 12.3557ZM1.47856 0C0.793018 0.358594 0.334229 1.0125 0.334229 1.86152V25.1332C0.334229 25.9822 0.793018 26.6361 1.47856 26.9947L15.0102 13.4947L1.47856 0ZM23.9012 11.8969L20.7952 10.0986L17.3305 13.5L20.7952 16.9014L23.9645 15.1031C24.9137 14.349 24.9137 12.651 23.9012 11.8969ZM4.51606 26.3145L19.3239 17.8137L16.1545 14.6443L4.51606 26.3145Z"
                    ></path>
                  </svg>
                  Google Play
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["allData"]),
  },
};
</script>

<style lang="scss" scoped>
#download-app {
  scroll-margin-top: 80px;
}

[class|="ctabox-element"] {
  background: linear-gradient(to bottom right, var(--black2), var(--black2));
  // box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 5%);

  .text p {
    color: var(--grey2);
  }
}

.app-stores {
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-size: 15px;

    svg {
      margin-right: 6px;
    }

    &:hover {
      color: var(--orangeLight);
    }

    &:hover svg {
      fill: var(--orangeLight);
    }

    &:first-child {
      background: var(--orangeDark) !important;
      border-color: var(--orangeDark) !important;

      &:hover {
        background: var(--white) !important;
        border-color: var(--white) !important;
      }
    }
  }
}
</style>