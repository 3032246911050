import { render, staticRenderFns } from "./Cashwyre4BusinessServices.vue?vue&type=template&id=2f0f5556&scoped=true"
import script from "./Cashwyre4BusinessServices.vue?vue&type=script&lang=js"
export * from "./Cashwyre4BusinessServices.vue?vue&type=script&lang=js"
import style0 from "./Cashwyre4BusinessServices.vue?vue&type=style&index=0&id=2f0f5556&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0f5556",
  null
  
)

export default component.exports