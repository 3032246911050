<template>
    <section id="business">
        <div class="container">
            <div data-aos="fade-up" class="row pb-40">
                <div class="col-12">
                    <div class="ctabox-element--center--round">
                        <div class="sectiontitle-element--center">
                            <h2>What is Cashwyre For Business</h2>

                            <p class="pb-0">
                                Cashwyre For Business provides a suite of
                                user-friendly RESTful APIs designed to deliver
                                seamless payment solutions that efficiently
                                support your business operations. Our mission is
                                to let you focus on your core activities while
                                we supply the payment infrastructure to power
                                and scale your business.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" class="row" data-padding="xs">
                <div class="col-12">
                    <div class="sectiontitle-element--center">
                        <h2>Our core services</h2>
                    </div>
                </div>

                <div class="col-12">
                    <div class="services-wrapper">
                        <div class="ctabox-element--center left">
                            <div
                                v-for="(
                                    service, index
                                ) in allBusinessServicesLeft"
                                :key="index"
                                class="inner-wrapper"
                            >
                                <div class="service-icon">
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM21.3333 10.6667H10.6667L10.6113 10.6675C9.65507 10.6968 8.88889 11.4811 8.88889 12.4444V19.5556L8.88973 19.6109C8.91899 20.5671 9.70335 21.3333 10.6667 21.3333H21.3333L21.3887 21.3325C22.3449 21.3032 23.1111 20.5189 23.1111 19.5556V12.4444L23.1103 12.3891C23.081 11.4329 22.2966 10.6667 21.3333 10.6667ZM21.9258 15.4073L21.9259 19.5556L21.9248 19.593C21.9054 19.9029 21.648 20.1481 21.3333 20.1481H10.6667L10.6292 20.147C10.3194 20.1276 10.0741 19.8702 10.0741 19.5556L10.074 15.4073H21.9258ZM21.3333 11.8519L21.3708 11.853C21.6806 11.8724 21.9259 12.1298 21.9259 12.4444L21.9258 14.222H10.074L10.0741 12.4444L10.0752 12.407C10.0946 12.0971 10.352 11.8519 10.6667 11.8519H21.3333Z"
                                        ></path>
                                    </svg>
                                </div>
                                <div class="service-content">
                                    <h3 class="service-title">
                                        {{ service.title }}
                                    </h3>
                                    <p class="service-title">
                                        {{ service.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="ctabox-element--center right">
                            <div
                                v-for="(
                                    service, index
                                ) in allBusinessServicesRight"
                                :key="index"
                                class="inner-wrapper"
                            >
                                <div class="service-icon">
                                    <svg
                                        width="32"
                                        height="32"
                                        viewBox="0 0 32 32"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM21.3333 10.6667H10.6667L10.6113 10.6675C9.65507 10.6968 8.88889 11.4811 8.88889 12.4444V19.5556L8.88973 19.6109C8.91899 20.5671 9.70335 21.3333 10.6667 21.3333H21.3333L21.3887 21.3325C22.3449 21.3032 23.1111 20.5189 23.1111 19.5556V12.4444L23.1103 12.3891C23.081 11.4329 22.2966 10.6667 21.3333 10.6667ZM21.9258 15.4073L21.9259 19.5556L21.9248 19.593C21.9054 19.9029 21.648 20.1481 21.3333 20.1481H10.6667L10.6292 20.147C10.3194 20.1276 10.0741 19.8702 10.0741 19.5556L10.074 15.4073H21.9258ZM21.3333 11.8519L21.3708 11.853C21.6806 11.8724 21.9259 12.1298 21.9259 12.4444L21.9258 14.222H10.074L10.0741 12.4444L10.0752 12.407C10.0946 12.0971 10.352 11.8519 10.6667 11.8519H21.3333Z"
                                        ></path>
                                    </svg>
                                </div>
                                <div class="service-content">
                                    <h3 class="service-title">
                                        {{ service.title }}
                                    </h3>
                                    <p class="service-title">
                                        {{ service.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div data-aos="fade-up" data-aos-delay="200" class="row pt-50">
                <div class="col-12">
                    <div class="sectiontitle-element--center">
                        <h2>
                            Power your business with our payment and
                            subscription APIs
                        </h2>
                        <p>
                            With our secure APIs, you can seamlessly offer
                            payment services to your staff and customers.
                        </p>
                    </div>
                </div>

                <div class="partner_logo_container">
                    <div
                        v-for="(item, index) in allPartnerItems"
                        :key="index"
                        data-aos="fade-up"
                        :data-aos-delay="(index + 1) * 80"
                        class="partner_logo"
                    >
                        <img :src="item.src" :alt="`${item.name} logo`" />
                    </div>
                </div>
                <p class="col-12 text-center mx-auto">
                    New Businesses/Products Leveraging Our Secure APIs
                </p>
            </div>

            <!-- payout -->
            <div class="row align-items-lg-center pt-50">
                <div
                    data-aos="fade-up"
                    class="col-12 col-md-5 col-lg-5 order-2 order-md-1"
                >
                    <div class="square-element">
                        <div class="info">
                            <div class="image">
                                <img
                                    src="@/assets/img/business/payout.png"
                                    alt="payout"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-aos="fade-up"
                    class="col-12 col-md-7 col-lg-7 order-1 order-md-2"
                >
                    <div class="sectiontitle-element">
                        <span class="toptitle">Payout</span>
                        <h2>
                            Making payouts within Africa and beyond simple, safe
                            and secure
                        </h2>
                        <p>
                            Join the growing list of companies using our
                            Business APIs for remittance and disbursement across
                            Africa in local currencies.
                        </p>
                    </div>
                </div>
            </div>

            <!-- crypto wallets -->
            <div class="reverse_smaller_device row align-items-lg-center pt-50">
                <div data-aos="fade-up" class="col-12 col-md-7 col-lg-7">
                    <div class="sectiontitle-element">
                        <span class="toptitle">Crypto wallets</span>
                        <h2>
                            Issue crypto wallets in real-time to your customer
                            base
                        </h2>
                        <p>
                            Using our business APIs and intuitive dashboard, you
                            can effortlessly issue crypto wallets to your
                            customers or staff and manage them directly,
                            allowing them to receive tips, salaries, and
                            payments from anywhere in the world with ease.
                        </p>
                    </div>
                </div>
                <div data-aos="fade-up" class="col-12 col-md-5 col-lg-5">
                    <div class="square-element">
                        <div class="info">
                            <div class="image">
                                <img
                                    src="@/assets/img/business/crypto-wallets.png"
                                    alt="crypto wallets"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- dollar cards -->
            <div class="row align-items-lg-center pt-50">
                <div
                    data-aos="fade-up"
                    class="col-12 col-md-5 col-lg-5 order-2 order-md-1"
                >
                    <div class="square-element">
                        <div class="info">
                            <div class="image">
                                <img
                                    src="@/assets/img/business/card-services.png"
                                    alt="dollar cards"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    data-aos="fade-up"
                    class="col-12 col-md-7 col-lg-7 order-1 order-md-2"
                >
                    <div class="sectiontitle-element">
                        <span class="toptitle">Dollar cards</span>
                        <h2>
                            Easily issue and manage dollar cards for your
                            customers
                        </h2>
                        <p>
                            With our business APIs, you can now issue dollar
                            cards to your staff or customers and provide their
                            their usage with our user-friendly dashboard.
                        </p>
                    </div>
                </div>
            </div>

            <!-- bills payment -->
            <div class="row align-items-lg-center pt-50">
                <div data-aos="fade-up" class="col-12 col-md-7 col-lg-7">
                    <div class="sectiontitle-element">
                        <span class="toptitle">Bills payment</span>
                        <h2>
                            Effortless bill payments across Africa with
                            Cashwyre's business APIs
                        </h2>
                        <p>
                            Enhance your mobile apps and web solutions with our
                            bill payment suite, enabling seamless payments for
                            utilities across Africa—including airtime, data
                            subscriptions, electricity bills, and cable TV.
                        </p>
                    </div>
                </div>
                <div data-aos="fade-up" class="col-12 col-md-5 col-lg-5">
                    <div class="square-element">
                        <div class="info">
                            <div class="image">
                                <img
                                    src="@/assets/img/business/bills-payment.png"
                                    alt="bills payment"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            allBusinessServicesLeft: [
                {
                    title: "Payout service",
                    description:
                        "Integrate with our RESTful APIs for seamless payouts, remittances, and transfers across Africa in local currencies. Focus on growth while we expertly manage your payment infrastructure.",
                    // imageName: require("@/assets/img/services/wallet-service.png"),
                    // imageAlt: "Buy & Sell",
                },
                {
                    title: "Virtual card",
                    description:
                        "With our APIs and intuitive dashboard, you can now offer virtual card services to your staff or customer base, giving you the control to manage card usage and privileges effectively.",
                    // imageName: require("@/assets/img/services/wallet-service.png"),
                    // imageAlt: "Virtual card",
                },
            ],
            allBusinessServicesRight: [
                {
                    title: "Bills payment",
                    description:
                        "Our APIs make it easy to pay for utilities across Africa, including electricity bills, cable TV subscriptions, airtime, data plans, and more.",
                    description2: null,
                    listItems: null,
                    // imageName: require("@/assets/img/services/wallet-service.png"),
                    // imageAlt: "Virtual card",
                },
                {
                    title: "Crypto wallets",
                    description:
                        "Whether you're building an online store to accept cryptocurrency payments, adding crypto wallets to your HRM solution for remote gig payments, or more—Cashwyre's API is the perfect solution for you.",
                    // imageName: require("@/assets/img/services/wallet-service.png"),
                    // imageAlt: "Send Money",
                },
            ],
            allPartnerItems: [
                {
                    name: "cashwyre",
                    src: require("@/assets/img/logos/logo-new-1.png"),
                },
                {
                    name: "vibeazy",
                    src: require("@/assets/img/logos/vibeazy-logo.png"),
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
#business {
    .sectiontitle-element--center,
    .sectiontitle-element {
        h2 {
            margin-bottom: 20px;
        }

        h2,
        h3 {
            text-transform: capitalize;
            color: var(--white);
        }
    }

    p,
    li,
    li:before {
        color: var(--grey2);
    }

    .partner_logo_container {
        width: 100%;
        max-width: 700px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 70px;
        padding: 20px 15px 5px;
        margin: 30px auto 0;

        & .partner_logo {
            max-width: 160px;

            & img {
                opacity: 0.8;
            }
        }
    }

    .square-element {
        .info {
            max-width: 500px;
        }

        & .image img {
            height: 100%;

            @media screen and (max-width: 767px) {
                & {
                    padding-top: 20px;
                }
            }
        }
    }

    .services-wrapper {
        // max-width: 900px;
        // margin-left: auto;
        // margin-right: auto;
        display: flex;
        justify-content: center;

        .ctabox-element--center {
            background: linear-gradient(
                to bottom right,
                var(--black2),
                var(--black2)
            );
            flex: 1;
            flex-wrap: nowrap;
            flex-direction: column;
            border-radius: 20px 0 0 20px;

            @media screen and (max-width: 375px) {
                padding-left: 25px;
                padding-right: 25px;
            }

            &.right {
                background: linear-gradient(
                    135deg,
                    rgba(197, 119, 5, 0.6) 60%,
                    rgba(197, 119, 5, 0.6) 90%
                );
                border-radius: 0 20px 20px 0;

                p {
                    color: var(--grey);
                }
            }

            .inner-wrapper {
                display: flex;
                justify-content: center;
                min-height: 140px;

                &:not(:last-child) {
                    margin-bottom: 35px;
                }

                .service-content {
                    padding-left: 22px;
                }
            }

            h3 {
                font-size: 18px;
                color: var(--white);
                margin-bottom: 10px;
                text-transform: capitalize;
            }

            p {
                margin-bottom: 0;
            }

            .icon {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                fill: var(--white);
            }
        }
    }

    @media screen and (max-width: 767px) {
        .services-wrapper {
            -ms-flex-direction: column;
            flex-direction: column;

            .ctabox-element--center {
                border-radius: 20px 20px 0 0;

                &.right {
                    border-radius: 0 0 20px 20px;
                }
            }
        }
    }
}
</style>
