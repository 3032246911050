<template>
  <transition name="fade">
    <div class="page_processing_modal" v-if="alertModalStatus">
      <div class="page_processing_card">
        <div
          class="message_stat"
          :class="alertModalType == 'success' ? 'text-success' : 'text-danger'"
        >
          <h4>Delivery Status</h4>
        </div>
        <p :class="alertModalType == 'success' ? 'text-dark' : 'text-danger'">
          {{ alertModalMessage }}
        </p>
        <button
          class="btn--medium--round"
          :class="alertModalType == 'success' ? 'btn-success ' : 'btn-danger'"
          @click="setalertModalStatus"
        >
          Okay
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["alertModalMessage", "alertModalType"]),
    ...mapState(["alertModalStatus"]),
  },

  methods: {
    ...mapActions(["setAlertModalStatus"]),
    setalertModalStatus: function () {
      this.setAlertModalStatus({
        type: "",
        status: false,
        message: "",
      });
    },
  },
};
</script>

<style scoped>
.page_processing_modal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(94, 99, 95, 0.699);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 69990 !important;
  transition: 800ms ease;
}

.page_processing_card {
  background: rgb(255, 255, 255);
  width: 320px;
  height: 230px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 3px 6px 10px 0 rgb(0 102 245 / 7%);
  text-align: center;
}
.page_processing_card::-webkit-scrollbar {
  width: 0px;
}
.page_processing_card h4 {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.page_processing_card p {
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}

.page_processing_card button {
  width: 38%;
  font-weight: bold;
  cursor: pointer;
  background: var(--orangeLight);
  color: #fff;
}

.page_processing_card img {
  width: 50px;
}

.message_stat {
  border-radius: 50%;
  padding: 13px 15px 10;
}

.message_stat .fa {
  font-size: 20px;
}

@media screen and (max-width: 350px) {
  .page_processing_card {
    width: 95%;
    margin: auto;
  }
}
</style>
