<template>
    <section id="why-cashwyre" data-padding="sm">
        <div class="container">
            <div data-aos="fade-up" class="row pb-50">
                <div class="col-12">
                    <div class="sectiontitle-element--center">
                        <span class="toptitle">Secure, Seamless, Easy</span>
                        <h2>
                            Cashwyre is Easy to use and offers Secure
                            cross-border transfers
                        </h2>

                        <!-- <a href="#" class="btn--big--arrow--round">See more</a> -->
                    </div>
                </div>
            </div>

            <div class="row justify-content-center">
                <div
                    v-for="(item, index) in allItems"
                    :key="index"
                    data-aos="fade-up"
                    :data-aos-delay="(index + 1) * 80"
                    class="col-12 col-md-6 col-lg-4"
                >
                    <div class="ctabox-element--center--round">
                        <div class="icon-element">
                            <div class="info">
                                <div class="icon">
                                    <i :class="`icon ${item.icon}`"></i>
                                </div>

                                <div class="list-element--plus">
                                    <p class="md f500">{{ item.title }}</p>

                                    <div class="separator-element"></div>

                                    <p class="description">
                                        {{ item.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            allItems: [
                {
                    icon: "ti-lock",
                    title: "Secure Payments",
                    description:
                        "Your transactions on Cashwyre are fully secured and protected.",
                },
                // {
                //     icon: "ti-layers-alt",
                //     title: "Seamless Transfers",
                //     description:
                //         "On Cashwyre, money can be sent directly into bank accounts or mobile Telco wallets seamlessly",
                // },
                {
                    icon: "ti-user",
                    title: "Customer Satisfaction",
                    description:
                        "On Cashwyre, we are customer-centric. Your satisfaction is our No 1 priority.",
                },
                {
                    icon: "ti-stats-up",
                    title: "Ease of use",
                    description:
                        "The Cashwyre platform focuses on simplicity and ease of use.",
                },
                // {
                //     icon: "ti-headphone",
                //     title: "Excellent Customer Service",
                //     description: "We offer quality customer support service",
                // },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
#why-cashwyre {
    [class|="ctabox-element"] {
        justify-content: center;
        background: linear-gradient(
            to bottom right,
            var(--black2),
            var(--black2)
        );
    }

    .sectiontitle-element--center {
        h2 {
            color: var(--white);
        }

        p {
            color: var(--grey2);
        }
    }

    .icon-element {
        color: var(--grey2);
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;

        .info {
            -ms-flex-direction: column;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 0;

            .icon {
                margin-bottom: 1.125rem;

                i {
                    font-size: 4rem;
                }
            }

            .list-element--plus {
                & .md {
                    font-size: 1.25rem;
                    color: var(--white);
                }

                .separator-element {
                    border-top-color: var(--grey2-transp);
                }

                .description {
                    margin-top: 0.875rem;
                    margin-bottom: 0;
                    color: var(--grey2);
                }
            }
        }
    }
}
</style>
