<template>
    <section id="calculate-amount" data-aos="fade" data-padding="sm">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="ctabox-element--input--round">
                        <div class="text">
                            <h2>Calculate Send or Receive Amount</h2>
                            <p>
                                To calculate, kindly enter the Send or Receive
                                Amount and select the currencies, the system
                                will estimate what to expect if you proceed with
                                the transaction immediately.
                                <a
                                    href="#download-app"
                                    class="simple--dotted"
                                    rel="noopener noreferrer"
                                    >Send money freely today!</a
                                >
                            </p>
                        </div>
                        <div class="cta">
                            <form @submit.prevent="calculateAmount">
                                <div
                                    class="newsletter-form--round--right--secondary--v2 mb-10"
                                >
                                    <input
                                        type="text"
                                        name="sendAmount"
                                        id="sendAmount"
                                        placeholder="Send Amount"
                                        v-model.number="sendAmount"
                                        @input="checkSendAmount"
                                    />
                                    <select
                                        name="sendCurrency"
                                        id="sendCurrency"
                                        class="submit-wrap currency"
                                        :class="
                                            sendCurrency && isSelected
                                                ? 'selected'
                                                : ''
                                        "
                                        v-model="sendCurrency"
                                        @input="handleSelect"
                                    >
                                        <option disabled value="">
                                            Select send currency
                                        </option>

                                        <option
                                            v-for="sendCurrency in allCurrencyInfo.sendCountryCurrencies"
                                            :key="`${sendCurrency.countryCode}-${sendCurrency.currencyCode}`"
                                            :value="sendCurrency.currencyCode"
                                        >
                                            <!-- <span class="currency-icon">
                                                <img
                                                    :src="sendCurrency.imageUrl"
                                                    :alt="`${sendCurrency.countryName} icon`"
                                                />
                                            </span> -->
                                            <span>
                                                {{ sendCurrency.countryName }}
                                                <!-- ({{ sendCurrency.currencyCode }}) -->
                                            </span>
                                        </option>
                                    </select>
                                </div>

                                <div
                                    class="newsletter-form--round--right--secondary--v2 mb-10"
                                >
                                    <input
                                        type="text"
                                        name="receiveAmount"
                                        id="receiveAmount"
                                        placeholder="Receive Amount (optional)"
                                        v-model.number="receiveAmount"
                                        @input="checkReceiveAmount"
                                    />
                                    <select
                                        name="receiveCurrency"
                                        id="receiveCurrency"
                                        class="submit-wrap currency"
                                        :class="
                                            receiveCurrency && isSelected
                                                ? 'selected'
                                                : ''
                                        "
                                        v-model="receiveCurrency"
                                        @input="handleSelect"
                                    >
                                        <option disabled value="">
                                            Select receive currency
                                        </option>

                                        <option
                                            v-for="receiveCurrency in allCurrencyInfo.receiveCountryCurrencies"
                                            :key="`${receiveCurrency.countryCode}-${receiveCurrency.currencyCode}`"
                                            :value="
                                                receiveCurrency.currencyCode
                                            "
                                        >
                                            <!-- <span class="currency-icon">
                                                <img
                                                    :src="receiveCurrency.imageUrl"
                                                    :alt="`${receiveCurrency.countryName} icon`"
                                                />
                                            </span> -->
                                            <span>
                                                {{
                                                    receiveCurrency.countryName
                                                }}
                                                <!-- ({{ receiveCurrency.currencyCode }}) -->
                                            </span>
                                        </option>
                                    </select>
                                </div>

                                <div
                                    class="newsletter-form--round--right text-right"
                                >
                                    <button
                                        type="submit"
                                        class="btn--big--round"
                                        :disabled="!isValid"
                                    >
                                        <span v-if="!isLoading">Calculate</span>

                                        <!-- fas fa-circle-notch fa-spin -->
                                        <span
                                            class="fas fa-spinner fa-pulse"
                                            role="status"
                                            v-else
                                        >
                                            <span class="sr-only"
                                                >Loading...</span
                                            >
                                        </span>
                                    </button>
                                </div>
                            </form>

                            <!-- <div class="calculation-result" v-show="isValid"> -->
                            <div
                                class="calculation-result"
                                v-if="result !== null && isResultAvailable"
                            >
                                <div class="destinations">
                                    <p class="sender">
                                        Send Amount:
                                        <span
                                            >{{ result.sendCurrency }}
                                            {{
                                                addComma(
                                                    result.sendAmount.toFixed(0)
                                                )
                                            }}</span
                                        >
                                    </p>
                                    <p class="receiver">
                                        Receive Amount:
                                        <span
                                            >{{ result.receiveCurrency }}
                                            {{
                                                addComma(
                                                    result.receiveAmount.toFixed(
                                                        0
                                                    )
                                                )
                                            }}</span
                                        >
                                    </p>
                                    <p class="amount-entered">
                                        Rate:
                                        <span>
                                            {{ result.sendRateInfo }}
                                        </span>
                                    </p>
                                </div>

                                <div class="result">
                                    <p>
                                        <span>
                                            {{ result.description }}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div
                                class="calculation-result error"
                                v-if="
                                    !allCurrencyInfo ||
                                    (setModalMessage && !setModalStatus)
                                "
                            >
                                <p>{{ setModalMessage }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import "@/mixins";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    data() {
        return {
            sendAmount: null,
            receiveAmount: null,
            sendCurrency: "",
            receiveCurrency: "",
            isResultAvailable: false,
            isSelected: false,
            result: null,
        };
    },

    computed: {
        ...mapState(["AppId", "RequestId"]),

        ...mapGetters([
            "getCurrencyInfo",
            "calculationResult",
            "alertModalMessage",
            "alertModalStatus",
            "getIsCalculating",
        ]),

        allCurrencyInfo() {
            let currencyInfo = {};

            if (this.getCurrencyInfo) {
                // console.log(this.getCurrencyInfo);
                currencyInfo = this.getCurrencyInfo;
            }
            return currencyInfo;
        },

        isValidAmount() {
            return this.sendAmount !== null || this.receiveAmount !== null;
        },

        isValidCurrency() {
            return this.sendCurrency !== "" && this.receiveCurrency !== "";
        },

        isValid() {
            return this.isValidAmount && this.isValidCurrency;
        },

        setModalMessage() {
            let message = "";

            if (this.alertModalMessage) {
                message = this.alertModalMessage;
            }
            return message;
        },

        setModalStatus() {
            let status = null;

            if (this.alertModalStatus) {
                status = this.alertModalStatus;
            }
            return status;
        },

        isLoading() {
            let status = null;

            if (this.getIsCalculating) {
                status = this.getIsCalculating;
            }
            return status;
        },

        calculatedResult() {
            let result = null;

            if (this.calculationResult) {
                result = this.calculationResult;
            }
            return result;
        },
    },

    methods: {
        ...mapActions(["getCalculationResult", "setIsCalculating"]),

        handleSelect() {
            this.isSelected = true;
            this.isResultAvailable = false;
        },

        checkSendAmount(option) {
            let value = option.target.value;
            if (value === "") {
                this.sendAmount = null;
            }

            this.isResultAvailable = false;
        },

        checkReceiveAmount(option) {
            let value = option.target.value;
            if (value === "") {
                this.receiveAmount = null;
            }

            this.isResultAvailable = false;
        },

        clearFields() {
            this.sendAmount = null;
            this.receiveAmount = null;
            this.sendCurrency = "";
            this.receiveCurrency = "";
        },

        calculateAmount() {
            this.setIsCalculating(true);

            if (this.isValid) {
                // console.log(typeof this.sendCurrency);
                // console.log(typeof this.receiveCurrency);
                // console.log(typeof this.sendAmount);
                // console.log(typeof this.receiveAmount);

                let data = {
                    appId: this.AppId,
                    requestId: this.RequestId,
                    sendAmount: this.sendAmount,
                    sendCurrency: this.sendCurrency,
                    receiveAmount: this.receiveAmount,
                    receiveCurrency: this.receiveCurrency,
                };

                this.getCalculationResult(data);
            }
        },
    },

    watch: {
        calculatedResult() {
            if (this.calculatedResult) {
                this.result = this.calculatedResult;
                // console.log(this.result, this.calculatedResult);

                this.setIsCalculating(false);
                this.isSelected = false;
                this.isResultAvailable = true;
                this.clearFields();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
#calculate-amount {
    // background-color: var(--black1);
    // margin-top: -100px;

    @media screen and (max-width: 769px) {
        padding-bottom: 80px;
        margin-top: initial;
    }
}

[class|="ctabox-element"] {
    background: linear-gradient(to bottom right, var(--black2), var(--black2));
    box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 5%);
    align-items: stretch;

    p {
        color: var(--grey2);
    }

    @media (max-width: 480px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .text {
        h2 {
            color: var(--white);
        }

        a {
            max-width: max-content;
            color: var(--orangeLight);
        }
    }

    button {
        background-color: var(--grey2-transp);

        &:disabled {
            opacity: 0.8;
            cursor: not-allowed;
        }
    }

    [class|="newsletter-form"] {
        max-width: 400px;

        .btn--big--round:not(:disabled) {
            cursor: pointer;
        }

        input[type="text"] {
            padding: 0 1.25rem;
        }

        .destination-currency-wrapper {
            border: 2px solid var(--black2);
            border-radius: 6px;
            height: 48px;

            & button {
                width: 145px;
            }

            @media screen and (max-width: 480px) {
                margin-bottom: 75px;

                & button {
                    border-radius: 6px;
                    width: 100%;
                }
            }
        }

        select.submit-wrap {
            top: 0;
            height: 100%;
            font-size: 1rem;
            font-weight: 600;
            background: transparent;
            color: var(--black2);
            border: none;
            padding: 0 0.25rem;
            line-height: 48px;
            opacity: 0.6;
            cursor: pointer;
            -o-transition: 0.4s ease all;
            -moz-transition: 0.4s ease all;
            -webkit-transition: 0.4s ease all;
            transition: 0.4s ease all;

            // @media screen and (max-width: 480px) {
            //     color: var(--white);
            // }

            option {
                color: var(--black2);
            }

            &.selected,
            &:focus,
            &:active {
                outline: none;
                opacity: 1;
            }

            &.currency {
                position: absolute;
                right: 4px;
                width: 150px;
                border-left: 2px solid var(--black2);
                border-radius: 0 2px 2px 0;

                @media screen and (max-width: 480px) {
                    position: relative;
                    right: 0;
                    height: 48px;
                    width: 100%;
                    margin-top: 10px;
                    color: var(--white);
                    border: 2px solid var(--white);
                    border-radius: 6px;
                }
            }

            // &#receiveCurrency {
            //     position: relative;
            //     left: 0;
            //     right: 0;
            //     width: calc(100% - 150px);

            //     @media screen and (max-width: 480px) {
            //         width: 100%;
            //     }
            // }
        }

        @media screen and (max-width: 991px) {
            max-width: 100%;
        }
    }

    .calculation-result {
        max-width: 400px;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: auto;
        margin-top: 1rem;

        &.error p {
            color: var(--errorColor);
            text-align: right;
        }

        & p {
            font-size: 16px;
            margin-bottom: 0;
        }

        .destinations {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            p:not(:last-child) {
                margin-right: 20px;
            }

            span {
                color: var(--offWhite);
                margin-left: 2px;
            }
        }

        .result p {
            margin-top: 8px;
            line-height: 22px;

            span {
                font-size: 14px;
                color: var(--orangeLight);
            }
        }

        @media screen and (max-width: 991px) {
            max-width: 100%;
        }
    }
}
</style>
